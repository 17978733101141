const DHeadMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'audit.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-dashboard-line',
    active_icon: 'ri-dashboard-fill'
  },
  {
    title: 'Payment Vouchers',
    is_heading: false,
    is_active: false,
    link: {
      name: 'audit.payment-vouchers'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-profile-line',
    active_icon: 'ri-profile-fill'
  }
]

export default DHeadMenu
