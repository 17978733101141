const DAssociateMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'da.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-dashboard-line',
    active_icon: 'ri-dashboard-fill'
  },
  {
    title: 'Payment Vouchers',
    is_heading: false,
    is_active: false,
    link: {
      name: 'da.payment-vouchers'
    },
    class_name: 'mb-3',
    is_icon_class: true,
    icon: 'ri-profile-line',
    active_icon: 'ri-profile-fill'
  },
  {
    title: 'Payment Requests',
    is_heading: true,
    is_active: false,
    link: {
      name: 'da.payment-requests'
    },
    class_name: 'font-weight-bolder'
  },
  {
    title: 'Document Printing',
    is_heading: false,
    is_active: false,
    link: {
      name: 'da.payment-requests.document-printing'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-printer-line',
    active_icon: 'ri-printer-fill'
  },
  {
    title: 'Check Signing',
    is_heading: false,
    is_active: false,
    link: {
      name: 'da.payment-requests.check-signing'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-pen-nib-line',
    active_icon: 'ri-pen-nib-fill'
  },
  {
    title: 'Releasing',
    is_heading: false,
    is_active: false,
    link: {
      name: 'da.payment-requests.releasing'
    },
    class_name: 'mb-3',
    is_icon_class: true,
    icon: 'ri-user-shared-line',
    active_icon: 'ri-user-shared-fill'
  },
  {
    title: 'Reports',
    is_heading: true,
    is_active: false,
    link: {
      name: 'da.reports'
    },
    class_name: 'font-weight-bolder'
  },
  {
    title: 'Revolving Funds',
    is_heading: false,
    is_active: false,
    link: {
      name: 'da.reports.revolving-funds'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-file-pdf-line',
    active_icon: 'ri-file-pdf-fill'
  },
  {
    title: 'Credit Cards',
    is_heading: false,
    is_active: false,
    link: {
      name: 'da.reports.credit-cards'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-file-excel-2-line',
    active_icon: 'ri-file-excel-2-fill'
  }
]

export default DAssociateMenu
